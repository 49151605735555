<template>
  <div class="wrapper-default-page" :class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'">
    <div class="mb-3 d-flex flex-wrap gap-2">
      <b-form-group class="mb-0" :label="$t('table.agency')" label-size="sm" label-class="py-0 mb-0">
        <MultiSelect
          v-model="selects.agencies"
          class="multiselect-sm w-250px"
          :options="filterAgenciesList"
          :placeholder="$t('table.selectAgency')"
          label="name"
          track-by="id"
          data-automatic="projects-filter-agencies"
          :multiple="true"
          :close-on-select="false"
          @input="updateQuery()"
        >
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span v-if="values.length > 1 && !isOpen" class="multiselect__single text-dark">{{ $t('multiselect.selected') }} {{ values.length }}</span>
          </template>
        </MultiSelect>
      </b-form-group>

      <b-form-group class="mb-0" :label="$t('table.advertiser')" label-size="sm" label-class="py-0 mb-0">
        <MultiSelect
          v-model="selects.advertisers"
          :options="filterAdvertisersList"
          :placeholder="$t('table.selectAdvertiser')"
          label="name"
          track-by="id"
          data-automatic="projects-filter-advertiser"
          class="multiselect-sm w-250px"
          :loading="advertisersLoadingStatus"
          :internal-search="selects.agencies.length > 0 ? true : false"
          :multiple="true"
          :close-on-select="false"
          @search-change="asyncFindAdvertisers"
          @input="updateQuery()"
        >
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span v-if="values.length > 1 && !isOpen" class="multiselect__single text-dark">{{ $t('multiselect.selected') }} {{ values.length }}</span>
          </template>
        </MultiSelect>
      </b-form-group>

      <router-link
        v-if="$checkPermissions('mediaplan.summary')"
        id="mp-summary-btn"
        :to="{ name: 'mediaPlansSummary' }"
        target="_blank"
        class="btn btn-sm btn-outline-danger h-100"
        style="margin-top: 19px"
      >
        {{ $t('table.mediaPlansSummary') }}
      </router-link>

      <b-form-group class="mb-0" :label="$t('table.commercialType')" label-size="sm" label-class="py-0 mb-0">
        <MultiSelect
          v-model="selects.commercialTypes"
          :options="commercialType"
          :placeholder="$t('table.selectCommercialType')"
          label="name"
          track-by="id"
          :multiple="true"
          :close-on-select="false"
          data-automatic="projects-filter-commercial-types"
          class="multiselect-sm w-250px"
          @input="updateQuery()"
        >
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span v-if="values.length > 1 && !isOpen" class="multiselect__single text-dark">{{ $t('multiselect.selected') }} {{ values.length }}</span>
          </template>
        </MultiSelect>
      </b-form-group>

      <b-form-group class="mb-0" :label="$t('channelModal.year')" label-size="sm" label-class="py-0 mb-0">
        <MultiSelect
          v-model="selects.year"
          :options="filterYearsList"
          :placeholder="$t('channelModal.pickYear')"
          label="id"
          track-by="id"
          data-automatic="projects-filter-year"
          class="multiselect-sm w-250px"
        />
      </b-form-group>

      <div class="d-flex gap-2">
        <b-form-group class="mb-0" :label="$t('channelModal.from')" label-for="datepicker-buttons-from" label-size="sm" label-class="py-0 mb-0">
          <datepicker-wrapper
            id="datepicker-buttons-from"
            v-model="selects.date_from"
            clearable="true"
            class="w-150px"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            size="sm"
            :min="minYearDate"
            :max="maxYearDate"
            @input="selectedDateFrom"
          />
        </b-form-group>
        <b-form-group class="mb-0" :label="$t('channelModal.to')" label-for="datepicker-buttons-to" label-size="sm" label-class="py-0 mb-0">
          <datepicker-wrapper
            id="datepicker-buttons-to"
            v-model="selects.date_to"
            class="w-150px"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            size="sm"
            :min="getMaxDate(selects.date_from, minYearDate)"
            :max="maxYearDate"
            @input="selectedDateTo"
          />
        </b-form-group>
        <b-form-group>
          <b-button class="btn btn-sm btn-outline-danger h-100" style="margin-top: 19px" @click="resetPeriod()">{{ $t('table.clear') }}</b-button>
        </b-form-group>
      </div>

      <b-form-group class="mb-0" :label="$t('channelModal.channel')" label-size="sm" label-class="py-0 mb-0">
        <MultiSelect
          v-model="selects.channels"
          class="multiselect-sm w-250px"
          :options="filterChannelsList"
          :placeholder="$t('broadcastSideBar.selectChannel')"
          label="name"
          track-by="id"
          :multiple="true"
          :close-on-select="false"
          @input="updateQuery()"
        >
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span v-if="values.length > 1 && !isOpen" class="multiselect__single text-dark">{{ $t('multiselect.selected') }} {{ values.length }}</span>
          </template>
        </MultiSelect>
      </b-form-group>
    </div>
    <div class="d-flex mb-3 gap-3">
      <div class="form-group" style="flex-basis: 33.33%">
        <div class="d-flex w-100 mb-1 align-items-center">
          <label class="d-inline-block mr-2 mb-0 align-self-center">{{ $t('table.projects') }}</label>
          <form @submit.prevent="updatePartial('restore')">
            <b-input-group size="sm">
              <b-form-input
                v-model="selects.project_name"
                size="sm"
                class="w-250px"
                type="search"
                :placeholder="$t('table.name')"
                :disabled="disabledProjects"
              />
              <b-input-group-append>
                <b-button-enh type="submit" size="sm">{{ $t('search.search') }}</b-button-enh>
              </b-input-group-append>
            </b-input-group>
          </form>
        </div>
        <div class="d-flex justify-content-between">
          <select
            v-if="projects"
            v-model="project"
            size="10"
            class="custom-select mr-3"
            style="height: 240px"
            :disabled="disabledProjects"
            data-automatic="selecting-project"
          >
            <option
              v-for="(option, index) in filteredProjects"
              :key="index"
              :value="option"
              :class="['pom-element', { 'pom-element-is-closed': option.is_closed }]"
            >
              {{ option.name }}
            </option>
          </select>
          <div class="d-flex flex-column">
            <b-form-group v-slot="{ ariaDescribedbySideFilterLeft }" class="mb-2 d-flex flex-column" data-automatic="projects-type-filter">
              <b-form-radio-group
                v-model="selectedLeft"
                :aria-describedby="ariaDescribedbySideFilterLeft"
                name="radio-filter-left"
                :options="POMFilterOptions"
                stacked
              >
              </b-form-radio-group>
            </b-form-group>
            <b-button
              v-if="canCreateProject"
              data-automatic="project-add"
              class="mb-2"
              :disabled="loading"
              @click="loadProjectModal('modal-project', 'add', 0)"
            >
              {{ $t('table.add') }}
            </b-button>
            <b-button
              v-if="canViewProject"
              data-automatic="project-edit"
              class="mb-2"
              :disabled="!(project && !loading) || disabledOrders"
              @click="loadProjectModal('modal-project', 'edit', orders.length)"
            >
              {{ canEditProject ? $t('table.edit') : $t('table.details') }}
            </b-button>
            <b-button
              v-if="canDeleteProject"
              data-automatic="project-delete"
              :disabled="!project"
              @click="[(isOpenModalProject = 'delete-project-confirm'), (modalNameOpen = 'delete')]"
            >
              {{ $t('table.delete') }}
            </b-button>
          </div>
        </div>
        <BudgetsTable v-if="canViewPOMBudgets" :entity="project" />
      </div>
      <div class="form-group" style="flex-basis: 33.33%">
        <div class="d-flex w-100 mb-1 align-items-center">
          <label class="d-inline-block mr-2 mb-0 align-self-center">{{ $t('table.orders') }}</label>
          <MultiSelect
            v-model="selects.month"
            class="multiselect-sm w-250px d-inline-block"
            :options="monthsList"
            :placeholder="$t('table.pickMonth')"
            label="name"
            track-by="id"
            :disabled="!(selects.year && selects.year.id)"
            data-automatic="projects-filter-month"
            @input="monthFilterSelected()"
          />
        </div>
        <div class="d-flex justify-content-between w-100">
          <select
            v-if="orders"
            v-model="order"
            data-automatic="selecting-order"
            size="10"
            class="custom-select mr-3"
            style="height: 240px"
            :disabled="disabledOrders"
          >
            <option
              v-for="(option, index) in filteredOrders"
              :key="index"
              :value="option"
              :class="['pom-element', { 'pom-element-is-closed': option.is_closed }]"
            >
              {{ option.name }}
            </option>
          </select>
          <div class="d-flex flex-column">
            <b-form-group v-slot="{ ariaDescribedbySideFilterCenter }" class="mb-2 d-flex flex-column" data-automatic="orders-type-filter">
              <b-form-radio-group
                v-model="selectedCenter"
                :aria-describedby="ariaDescribedbySideFilterCenter"
                name="radio-filter-center"
                :options="POMFilterOptions"
                stacked
              >
              </b-form-radio-group>
            </b-form-group>
            <b-button
              v-if="canCreateOrder"
              class="mb-2"
              data-automatic="order-add"
              :disabled="!(project && !loading)"
              @click="loadOrderModal('modal-order', 'add', 0)"
            >
              {{ $t('table.add') }}
            </b-button>
            <b-button
              v-if="canViewOrder"
              class="mb-2"
              data-automatic="order-edit"
              :disabled="!(project && order && !loading) || disabledMediaplans"
              @click="loadOrderModal('modal-order', 'edit', mediaPlans.length)"
            >
              {{ canEditOrder ? $t('table.edit') : $t('table.details') }}
            </b-button>
            <b-button
              v-if="canDeleteOrder"
              data-automatic="order-delete"
              :disabled="!order"
              @click="[(isOpenModalOrders = 'delete-order-confirm'), (modalNameOpen = 'delete')]"
            >
              {{ $t('table.delete') }}
            </b-button>
          </div>
        </div>
        <BudgetsTable v-if="canViewPOMBudgets" :entity="order" />
      </div>
      <div class="form-group" style="flex-basis: 33.33%">
        <div class="d-flex w-100 mb-1 align-items-center">
          <label class="d-inline-block mr-2 mb-0 align-self-center ws-nowrap">{{ $t('table.mediaPlans') }}</label>
          <MultiSelect
            v-model="selects.type"
            class="multiselect-sm w-250px d-inline-block"
            :options="placementType"
            :placeholder="$t('table.selectType')"
            label="name"
            track-by="id"
            :disabled="!order"
            @input="filterMediaPlansByPlacementType()"
          />
        </div>
        <div class="d-flex justify-content-between w-100">
          <select
            v-if="mediaPlans"
            v-model="mediaPlan"
            data-automatic="selecting-media-plans"
            size="10"
            class="custom-select mr-3"
            style="height: 240px"
            :disabled="disabledMediaplans"
          >
            <option
              v-for="(option, index) in filteredMediaPlans"
              :key="index"
              :value="option"
              :class="[setPlacementClass(option.placement_type_id), { 'pom-element-is-closed': option.is_closed }, 'pom-element']"
            >
              {{ option.name }}
            </option>
          </select>
          <div class="d-flex flex-column">
            <b-form-group v-slot="{ ariaDescribedbySideFilterRight }" class="mb-2 d-flex flex-column" data-automatic="media-plans-type-filter">
              <b-form-radio-group
                v-model="selectedRight"
                :aria-describedby="ariaDescribedbySideFilterRight"
                name="radio-filter-right"
                :options="POMFilterOptions"
                stacked
              >
              </b-form-radio-group>
            </b-form-group>
            <b-button
              v-if="canCreateMediaPlan"
              class="mb-2"
              data-automatic="media-plan-add"
              :disabled="!(order && !loading)"
              @click="loadMediaPlanModal('modal-media-plan', 'add')"
            >
              {{ $t('table.add') }}
            </b-button>
            <b-button
              v-if="canViewMediaPlan"
              class="mb-2"
              data-automatic="media-plan-edit"
              :disabled="!(order && mediaPlan && !loading)"
              @click="loadMediaPlanModal('modal-media-plan', 'edit')"
            >
              {{ canEditMediaPlan ? $t('table.edit') : $t('table.details') }}
            </b-button>
            <b-button
              v-if="canDeleteMediaPlan"
              data-automatic="media-plan-delete"
              :disabled="!(order && mediaPlan)"
              @click="[(isOpenModalMediaPlan = 'delete-media-plan-confirm'), (modalNameOpen = 'delete')]"
            >
              {{ $t('table.delete') }}
            </b-button>
          </div>
        </div>
        <BudgetsTable v-if="canViewPOMBudgets" :entity="mediaPlan" />
      </div>
    </div>
    <div class="d-flex mb-3 align-items-center">
      <div v-if="project && order && mediaPlan" class="d-flex align-items-center">
        <h5 class="mb-0">
          <abbr :title="mediaPlanPeriodTitle">{{ getMonthName(mediaPlan.date_from) + ' ' + mediaPlan.date_from.slice(0, 4) }}</abbr>
          {{ mediaPlan.channel.name }}
        </h5>
        <b-button size="sm" class="ml-4" data-automatic="toggle-media-plan-table-list" @click="changeMediaPlanTableListView">
          {{ isListView ? $t('table.table') : $t('table.list') }}
        </b-button>
      </div>
      <div class="d-flex ml-auto gap-2">
        <b-button v-if="canViewDiscounts" class="ml-auto" data-automatic="price-modal-btn" :disabled="!(order && mediaPlan && !loading)" @click="openPrices">
          {{ $t('table.price') }}
        </b-button>
        <router-link
          v-if="$checkPermissions('booking.channel_grid')"
          class="btn btn-outline-danger"
          :to="{
            name: 'booking',
            query: {
              agency_id: project ? project.agency_id : selects.agencies.length === 1 && selects.agencies[0].id != null ? selects.agencies[0].id : undefined,
              advertiser_id: project
                ? project.advertiser_id
                : selects.advertisers.length === 1 && selects.advertisers[0].id != null
                ? selects.advertisers[0].id
                : undefined,
              project_id: project ? project.id : undefined,
              order_id: order ? order.id : undefined,
              mediaplan_id: mediaPlan ? mediaPlan.id : undefined,
              channel_id: mediaPlan
                ? mediaPlan.channel_id
                : selects.channels.length === 1 && selects.channels[0].id != null
                ? selects.channels[0].id
                : undefined,
              date: mediaPlan ? mediaPlan.date_from : undefined,
              target_id: mediaPlan ? mediaPlan.target_audience_id : undefined,
              commercial_type_id: mediaPlan ? mediaPlan.commercial_type_id : undefined,
            },
          }"
        >
          {{ $t('table.booking') }}
        </router-link>
        <router-link
          v-if="$checkPermissions('scope.mediaplan_automatic_list')"
          :to="{
            name: 'automaticBooking',
            query: {
              channel_id: mediaPlan ? mediaPlan.channel_id : undefined,
              date_from: mediaPlan ? mediaPlan.date_from : undefined,
              date_to: mediaPlan ? mediaPlan.date_to : undefined,
            },
          }"
          class="btn btn-outline-danger"
        >
          {{ $t('table.automaton') }}
        </router-link>
        <router-link :to="{ name: 'profile' }" class="btn btn-outline-danger">
          {{ $t('broadcastSideBar.exit') }}
        </router-link>
      </div>
    </div>
    <MediaPlanSection
      v-if="project && order && mediaPlan"
      ref="media-plan-section"
      :media-plan="mediaPlan"
      :is-list-view="isListView"
      :loading="loading"
      @updateData="updatePartial('restore')"
    />

    <ModalProject
      :is-open-modal="isOpenModalProject"
      :modal-name="modalNameOpen"
      :modal-current-project="project"
      :has-orders="modalProjectHasOrders"
      :can-edit="canEditProject"
      :modal-project-busy-channel-ids="modalProjectBusyChannelIds"
      @isOpenModal="isOpenModalProject = null"
      @refreshProjects="updatePartial"
    />
    <ModalOrders
      v-if="project"
      :is-open-modal="isOpenModalOrders"
      :modal-name="modalNameOpen"
      :modal-current-order="order || { project_id: project.id }"
      :channel-list="modalOrderChannelsList"
      :placement-type-id="project.placement_type_id"
      :commercial-type-id="project.commercial_type_id"
      :agency-id="project.agency_id"
      :project-name="project.name"
      :has-media-plans="modalOrderHasMediaPlans"
      :min-date="project.date_from"
      :max-date="project.date_to"
      :can-edit="canEditOrder"
      :modal-order-channels-and-t-a="modalOrderChannelsAndTA"
      :project-discounts="project.discounts"
      @isOpenModal="isOpenModalOrders = null"
      @refreshOrders="updatePartial"
    />
    <ModalMediaPlan
      v-if="project && order"
      :is-open-modal="isOpenModalMediaPlan"
      :modal-name="modalNameOpen"
      :modal-current-media-plan="mediaPlan || { order_id: order.id }"
      :order-settings="order.order_settings"
      :placement-type-id="order.placement_type_id"
      :commercial-type-id="order.commercial_type_id"
      :agency-id="project.agency_id"
      :project-id="project.id"
      :project-name="project.name"
      :order-name="order.name"
      :min-date="order.date_from"
      :max-date="order.date_to"
      :can-edit="canEditMediaPlan"
      :project-advertiser-id="project.advertiser_id"
      :project-discounts="project.discounts"
      :order-discounts="order.discounts"
      :allow-a-m-a-edit-m-p-c="project.are_mpc_settings_editable_by_agencies"
      :project="project"
      @isOpenModal="isOpenModalMediaPlan = null"
      @refreshMediaPlans="updatePartial"
    />

    <ModalDiscounts
      v-if="project && order && mediaPlan"
      modal-name="mediaPlan"
      :discounts-edit-list="mediaPlan.discounts"
      :premium-discounts-edit-list="mediaPlan.premium_position_discounts"
      modal-discounts-type="edit"
      :is-open-modal="isOpenModalPrices"
      :can-edit="false"
      :can-delete="false"
      :can-add="false"
      :prices-mode="true"
      :price-project="discountsPriceProject"
      :co-branding-discount="mediaPlan.co_branding_discount"
      :media-plan-placement-type-id="mediaPlan.placement_type_id"
      :channels_id_list="[mediaPlan.channel_id]"
      :date_from="mediaPlan.date_from"
      :date_to="mediaPlan.date_to"
      :project-discounts="project.discounts"
      :order-discounts="order.discounts"
      @isOpenModal="isOpenModalPrices = false"
      @clearDiscountsPriceProject="discountsPriceProject = {}"
    />
  </div>
</template>

<script>
import MultiSelect from '@/components/MultiSelect';
import { mapGetters } from 'vuex';
import downloadFileHandler from '@/mixins/downloadFileHandler';
import BudgetsTable from '@/components/Projects/BudgetsTable.vue';
import setPlacementClass from '@/mixins/setPlacementClass';
import BButtonEnh from '@/components/BButtonEnh.vue';
import debounce from '@/utils/debounce';
import { getAdvertisers } from '@/api/apiServices';
import getMinOrMaxDate from '@/mixins/getMinOrMaxDate';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';

export default {
  name: 'Projects',
  components: {
    MultiSelect,
    BudgetsTable,
    ModalProject: () => import('@/components/Projects/ModalProject'),
    ModalOrders: () => import('@/components/Projects/ModalOrders'),
    ModalMediaPlan: () => import('@/components/Projects/ModalMediaPlan'),
    ModalDiscounts: () => import('@/components/Projects/ModalDiscounts'),
    MediaPlanSection: () => import('@/components/Projects/MediaPlanSection'),
    BButtonEnh,
    DatepickerWrapper,
  },
  mixins: [downloadFileHandler, setPlacementClass, getMinOrMaxDate],
  props: {
    commercial_type_ids: { type: String, default: undefined },
    agency_ids: { type: String, default: undefined },
    advertiser_ids: { type: String, default: undefined },
    project_id: { type: [String, Number], default: undefined },
    order_id: { type: [String, Number], default: undefined },
    mediaplan_id: { type: [String, Number], default: undefined },
    selected_year: { type: [String, Number], default: undefined },
    month_id: { type: [String, Number], default: undefined },
    placement_type_id: { type: [String, Number], default: undefined },
    is_list_view: { type: [String, Number], default: undefined },
    project_name: { type: String, default: undefined },
    channel_ids: { type: String, default: undefined },
    date_from: { type: String, default: undefined },
    date_to: { type: String, default: undefined },
  },
  data() {
    return {
      modalNameOpen: null,
      isOpenModalProject: null,
      isOpenModalOrders: null,
      isOpenModalMediaPlan: null,
      isOpenModalPrices: false,

      modalProjectHasOrders: false,
      modalProjectBusyChannelIds: [],

      modalOrderHasMediaPlans: false,
      modalOrderChannelsList: [],
      modalOrderChannelsAndTA: [],

      discountsPriceProject: {},

      project: null,
      order: null,
      mediaPlan: null,
      selects: {
        agencies: [],
        advertisers: [],
        commercialTypes: [],
        year: null,
        month: null,
        type: null,
        project_name: null,
        channels: [],
        date_from: '',
        date_to: '',
      },
      selectedLeft: 'all',
      selectedCenter: 'all',
      selectedRight: 'all',
      loading: true,

      filterAgenciesList: [],
      filterYearsList: [],
      filterAdvertisersList: [],
      filterChannelsList: [],
      isListView: false,

      disabledOrders: true,
      disabledMediaplans: true,
      disabledProjects: true,
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      commercialType: 'getCommercialType',
      agenciesList: 'getAgenciesList',
      advertisersList: 'getAdvertisersList',
      year: 'getYear',
      projects: 'getProjectsList',
      orders: 'getOrdersList',
      mediaPlans: 'getMediaPlansList',
      placementType: 'getPlacementType',
      modalEditProjects: 'getModalEditProjects',
      advertisersStatus: 'getAdvertisersStatus',
      channels: 'getChannelsList',
    }),
    advertisersLoadingStatus() {
      return this.advertisersStatus === 'loading' ? true : false;
    },
    monthsList() {
      return [
        { id: null, name: this.$i18n.t('table.all') },
        { id: 0, name: this.$i18n.t('month.jan') },
        { id: 1, name: this.$i18n.t('month.feb') },
        { id: 2, name: this.$i18n.t('month.mar') },
        { id: 3, name: this.$i18n.t('month.apr') },
        { id: 4, name: this.$i18n.t('month.may') },
        { id: 5, name: this.$i18n.t('month.jun') },
        { id: 6, name: this.$i18n.t('month.jul') },
        { id: 7, name: this.$i18n.t('month.aug') },
        { id: 8, name: this.$i18n.t('month.sep') },
        { id: 9, name: this.$i18n.t('month.oct') },
        { id: 10, name: this.$i18n.t('month.nov') },
        { id: 11, name: this.$i18n.t('month.dec') },
      ];
    },
    POMFilterOptions() {
      return [
        { text: this.$t('table.all'), value: 'all' },
        { text: this.$t('table.open'), value: 'open' },
        { text: this.$t('table.closed'), value: 'closed' },
      ];
    },
    minYearDate() {
      return this.selects.year?.id ? this.selects.year.id + '-01-01' : undefined;
    },
    maxYearDate() {
      return this.selects.year?.id ? this.selects.year.id + '-12-31' : undefined;
    },
    canCreateProject() {
      return this.$checkPermissions('project.create');
    },
    canViewProject() {
      return this.$checkPermissions('project.read');
    },
    canEditProject() {
      return this.$checkPermissions('project.update');
    },
    canDeleteProject() {
      return this.$checkPermissions('project.delete');
    },
    canCreateOrder() {
      return this.$checkPermissions('order.create');
    },
    canViewOrder() {
      return this.$checkPermissions('order.read');
    },
    canEditOrder() {
      return this.$checkPermissions('order.update');
    },
    canDeleteOrder() {
      return this.$checkPermissions('order.delete');
    },
    canCreateMediaPlan() {
      return this.$checkPermissions('mediaplan.create');
    },
    canViewMediaPlan() {
      return this.$checkPermissions('mediaplan.read');
    },
    canEditMediaPlan() {
      return this.$checkPermissions('mediaplan.update');
    },
    canDeleteMediaPlan() {
      return this.$checkPermissions('mediaplan.delete');
    },
    canViewPOMBudgets() {
      return this.$checkPermissions('scope.pom_price');
    },
    canViewDiscounts() {
      return this.$checkPermissions('scope.pom_discount');
    },

    filteredProjects() {
      return this.projects?.filter((el) => (this.selectedLeft === 'closed' ? el.is_closed : this.selectedLeft === 'open' ? !el.is_closed : el)) || [];
    },
    filteredOrders() {
      if (!this.project) return [];
      if (this.selects.year?.id && this.selects.month && this.selects.month.id !== null) {
        return (
          this.orders?.filter(
            (el) =>
              //check for (closed / opened / all) + is in selected month
              (this.selectedCenter === 'closed' ? el.is_closed : this.selectedCenter === 'open' ? !el.is_closed : el) &&
              this.isEntityInMonthRange(el.date_from, el.date_to, this.selectedMonthStartEndDays[0], this.selectedMonthStartEndDays[1])
          ) || []
        );
      } else {
        return this.orders?.filter((el) => (this.selectedCenter === 'closed' ? el.is_closed : this.selectedCenter === 'open' ? !el.is_closed : el)) || [];
      }
    },
    filteredMediaPlans() {
      if (!this.order) return [];
      if (this.selects.year?.id && this.selects.month && this.selects.month.id !== null) {
        return (
          this.mediaPlans?.filter(
            (el) =>
              (this.selectedRight === 'closed' ? el.is_closed : this.selectedRight === 'open' ? !el.is_closed : el) &&
              this.isEntityInMonthRange(el.date_from, el.date_to, this.selectedMonthStartEndDays[0], this.selectedMonthStartEndDays[1])
          ) || []
        );
      } else {
        return this.mediaPlans?.filter((el) => (this.selectedRight === 'closed' ? el.is_closed : this.selectedRight === 'open' ? !el.is_closed : el)) || [];
      }
    },

    selectedMonthStartEndDays() {
      if (!(this.selects.year?.id && this.selects.month && this.selects.month.id !== null)) return null;
      const month_start = new Date(Date.UTC(+this.selects.year.id, this.selects.month.id, 1)).getTime();
      const month_end = new Date(Date.UTC(+this.selects.year.id, this.selects.month.id + 1, 0)).getTime();
      return [month_start, month_end];
    },

    mediaPlanPeriodTitle() {
      if (!this.mediaPlan) return undefined;
      return new Date(this.mediaPlan.date_from).toLocaleDateString() + ' - ' + new Date(this.mediaPlan.date_to).toLocaleDateString();
    },
  },
  watch: {
    async project() {
      if (this.loading) return;
      this.disabledOrders = true;
      this.disabledMediaplans = true;
      if (this.project) {
        await this.loadOrders();
        this.mediaPlan = null;
        this.order = null;
        this.$store.commit('clearMediaPlans');
        this.disabledOrders = false;
      }
      this.updateQuery();
    },
    async order() {
      if (this.loading) return;
      this.disabledMediaplans = true;
      if (this.order) {
        this.mediaPlan = null;
        await this.loadMediaPlansAndCommercials();
        this.disabledMediaplans = false;
      }
      this.updateQuery();
    },
    async mediaPlan() {
      if (this.loading) return;
      this.updateQuery();
    },

    // Filters
    async 'selects.agencies'(newValue) {
      if (this.loading) return;
      // if All is selected - reset array
      const ag_ids = newValue.map((e) => e.id);
      if (ag_ids.includes(null)) {
        this.selects.agencies = [];
        return;
      }
      if (newValue.length > 0) await this.loadAdvertisers();

      this.mediaPlan = null;
      this.order = null;
      this.project = null;
      this.$store.commit('clearOrders');
      this.$store.commit('clearMediaPlans');

      await this.loadProjectsList();
    },
    async 'selects.advertisers'(newValue) {
      if (this.loading) return;
      // if All is selected - reset array
      const adv_ids = newValue.map((e) => e.id);
      if (adv_ids.includes(null)) {
        this.selects.advertisers = [];
        return;
      }
      this.mediaPlan = null;
      this.order = null;
      this.project = null;
      this.$store.commit('clearOrders');
      this.$store.commit('clearMediaPlans');
      await this.loadProjectsList();
    },
    async 'selects.commercialTypes'(newValue) {
      if (this.loading) return;
      // if All is selected - reset array
      const ct_ids = newValue.map((e) => e.id);
      if (ct_ids.includes(null)) {
        this.selects.commercialTypes = [];
        return;
      }
      this.mediaPlan = null;
      this.order = null;
      this.project = null;
      this.$store.commit('clearOrders');
      this.$store.commit('clearMediaPlans');
      await this.loadProjectsList();
    },
    async 'selects.channels'(newValue) {
      if (this.loading) return;
      // if All is selected - reset array
      const ch_ids = newValue.map((e) => e.id);
      if (ch_ids.includes(null)) {
        this.selects.channels = [];
        return;
      }
      this.mediaPlan = null;
      this.order = null;
      this.project = null;
      this.$store.commit('clearOrders');
      this.$store.commit('clearMediaPlans');
      await this.loadProjectsList();
    },
    async 'selects.year'(newValue) {
      if (this.loading) return;
      this.updateFromTo(newValue.id);
      this.updateQuery();

      this.project = null;
      this.order = null;
      this.mediaPlan = null;
      this.$store.commit('clearOrders');
      this.$store.commit('clearMediaPlans');
      await this.loadProjectsList();
    },
    // Close/open filter
    selectedLeft() {
      if (this.selectedLeft !== 'all') {
        this.mediaPlan = null;
        this.order = null;
        this.project = null;
        this.$store.commit('clearMediaPlans');
        this.$store.commit('clearOrders');
      }
    },
    selectedCenter() {
      if (this.selectedCenter !== 'all') {
        this.mediaPlan = null;
        this.$store.commit('clearMediaPlans');
        this.order = null;
      }
    },
    selectedRight() {
      if (this.selectedRight !== 'all') {
        this.mediaPlan = null;
      }
    },
  },
  created() {
    document.title = this.$i18n.t('sideBar.projects') + ' – OpenMediaLogic';
  },
  mounted() {
    this.$store.commit('clearOrders');
    this.$store.commit('clearMediaPlans');
    this.getData();
  },
  destroyed() {
    this.$store.commit('clearProjects');
    this.$store.commit('clearOrders');
    this.$store.commit('clearMediaPlans');
    this.$store.commit('clearAdvertisersList');
    this.$store.commit('clearTargetsList');
    this.$store.commit('clearBrandsList');
    this.$store.commit('clearCommercialsList');
    this.$store.commit('clearPriceProjects');
    this.$store.commit('cleanProgramReleases');
    this.$store.commit('clearModalEditPriceProjects');
    this.$store.commit('clearChannelsList');
  },
  methods: {
    async resetPeriod() {
      this.selects.date_from = null;
      this.selects.date_to = null;
      this.updateQuery();
      await this.loadProjectsList();
    },
    updateFromTo(year) {
      if (year) {
        let date_from = new Date(this.selects.date_from.slice(0, 10) + 'T05:00:00');
        let date_to = new Date(this.selects.date_to.slice(0, 10) + 'T05:00:00');
        this.selects.date_from = this.getDate(year, date_from.getMonth() ? date_from.getMonth() : '', date_from.getDate() ? date_from.getDate() : 1);
        this.selects.date_to = this.getDate(
          year,
          date_to.getMonth() ? date_to.getMonth() : '',
          date_to.getDate() ? date_to.getDate() : new Date(year, new Date().getMonth() + 1, 0).getDate()
        );
      }
    },
    getDate(year = '', month = '', day = '') {
      const now = new Date();
      if (year != '') {
        now.setFullYear(year);
      }
      if (month != '') {
        now.setMonth(month);
      }
      if (day != '') {
        now.setDate(day);
      }
      return new Date(now.toJSON().slice(0, 10)).toISOString();
    },
    async selectedDateFrom() {
      // Rewrite date TO if it is lower than FROM
      if (new Date(this.selects.date_from) > new Date(this.selects.date_to)) {
        this.selects.date_to = this.selects.date_from;
      }
      this.updateQuery();
      await this.loadProjectsList();
    },
    async selectedDateTo() {
      this.updateQuery();
      await this.loadProjectsList();
    },
    async loadAdvertisers(query) {
      await this.$store.dispatch('GET_ADVERTISERS', {
        'filter[name]': query || undefined,
        'filter[agency_id]': this.selects.agencies.map((a) => a.id),
        include: 'agencies',
        per_page: 1000,
      });
      this.filterAdvertisersList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.advertisersList];
    },

    asyncFindAdvertisers: debounce(function (query) {
      if (query && this.selects.agencies.length === 0) {
        this.loadAdvertisers(query);
      }
    }, 700),

    async loadProjectsList() {
      this.disabledProjects = true;
      this.$store.commit('clearProjects');
      // this.$store.commit('clearOrders');
      // this.$store.commit('clearMediaPlans');
      await this.$store.dispatch('GET_PROJECTS', {
        per_page: 1000,
        include: 'discounts',
        'filter[agency_id]': this.selects.agencies.map((a) => a.id),
        'filter[advertiser_id]': this.selects.advertisers.map((adv) => adv.id),
        'filter[channels.id]': this.selects.channels.map((ch) => ch.id),
        'filter[year_id]': this.selects.year ? this.selects.year.id : null,
        'filter[commercial_type_id]': this.selects.commercialTypes.length > 0 ? this.selects.commercialTypes.map((c) => c.id) : null,
        'filter[name]': this.selects.project_name || undefined,
        date_from: this.selects.date_from ? this.selects.date_from.slice(0, 10) : undefined,
        date_to: this.selects.date_to ? this.selects.date_to.slice(0, 10) : undefined,
      });
      this.disabledProjects = false;
    },

    async loadOrders() {
      this.$store.commit('clearOrders');
      if (!this.project?.id) return;
      await Promise.all([
        this.$store.dispatch('GET_ORDERS', {
          'filter[project_id]': this.project.id,
          include: 'orderSettings,discounts',
          per_page: 1000,
        }),
        this.$store.dispatch('GET_BRANDS', {
          'filter[advertiser_id]': this.project.advertiser_id,
          per_page: 1000,
        }),
      ]);
    },

    async loadMediaPlansAndCommercials() {
      this.$store.commit('clearMediaPlans');
      if (!this.order?.id) return;
      await this.$store.dispatch('GET_MEDIA_PLANS', {
        'filter[order_id]': this.order.id,
        'filter[placement_type_id]': this.selects.type?.id,
        include: 'channel,commercials,discounts,premiumPositionDiscounts',
        per_page: 1000,
      });
    },

    async filterMediaPlansByPlacementType() {
      this.disabledMediaplans = true;
      await this.loadMediaPlansAndCommercials();
      if (this.mediaPlan && !this.mediaPlans.find((e) => e.id === this.mediaPlan.id)) {
        this.mediaPlan = null;
      }
      this.disabledMediaplans = false;
      this.updateQuery();
    },

    async openPrices() {
      if (this.mediaPlan) {
        if (this.project) {
          if (this.canViewProject) {
            if (this.project.id !== this.modalEditProjects?.id)
              await this.$store.dispatch('GET_PROJECTS_ID', {
                id: this.project.id,
                data: { include: 'channelProjects.channelProjectPriceProject,channelProjects.channel' },
              });
            const priceProject = this.modalEditProjects?.channel_projects.find(
              (el) => el.channel_id === this.mediaPlan.channel_id
            )?.channel_project_price_project;
            this.discountsPriceProject = {};
            if (priceProject && (priceProject.id || priceProject.price_project_id)) {
              this.discountsPriceProject = priceProject;
              this.discountsPriceProject.channel_name = this.mediaPlan.channel?.name;
              if (priceProject.type === 'fix_grp' || priceProject.price_project_type === 'fix_grp') {
                this.discountsPriceProject.channel_project_price_project_by_grp_details =
                  this.discountsPriceProject?.channel_project_price_project_by_grp_details?.filter(
                    (e) => e.target_audience_id === this.mediaPlan.target_audience_id
                  );
              }
            }
          }
        }
        this.isOpenModalPrices = true;
      }
    },

    async loadProps() {
      if (this.project_name) this.selects.project_name = this.project_name;
      if (this.placement_type_id && /^\d+$/.test(this.placement_type_id))
        this.selects.type = this.placementType.find((e) => e.id === +this.placement_type_id) || null;
      if (this.agency_ids) {
        const ag_ids = this.agency_ids.split(',').map((e) => +e);
        this.selects.agencies = this.agenciesList.filter((el) => ag_ids.includes(el.id));
        if (this.selects.agencies.length > 0) await this.loadAdvertisers();
      }
      if (this.advertiser_ids) {
        const adv_ids = this.advertiser_ids.split(',').map((e) => +e);
        if (this.filterAdvertisersList.length > 0) this.selects.advertisers = this.advertisersList.filter((el) => adv_ids.includes(el.id));
        else {
          // restore filter without selected parents
          await getAdvertisers({
            'filter[id]': adv_ids,
            'filter[agency_id]': this.selects.agencies.map((a) => a.id),
            include: 'agencies',
            per_page: 1000,
          })
            .then((res) => {
              const advList = res.data?.data || [];
              this.selects.advertisers = advList.filter((el) => adv_ids.includes(el.id));
            })
            .catch((e) => {});
        }
      }
      if (this.channel_ids) {
        const ch_ids = this.channel_ids.split(',').map((e) => +e);
        this.selects.channels = this.filterChannelsList.filter((el) => ch_ids.includes(el.id));
      }
      if (this.commercial_type_ids) {
        const ct_ids = this.commercial_type_ids.split(',').map((e) => +e);
        this.selects.commercialTypes = this.commercialType.filter((el) => ct_ids.includes(el.id));
      }
      if (this.selected_year && /\d{4}/.test(this.selected_year)) this.selects.year = this.year.find((el) => el.id === +this.selected_year);
      if (!this.selects.year) this.selects.year = this.year.find((el) => el.id === +new Date().getFullYear());
      if (this.date_from && /\d{4}-\d{2}-\d{2}/.test(this.date_from)) this.selects.date_from = this.date_from;
      if (this.date_to && /\d{4}-\d{2}-\d{2}/.test(this.date_to)) this.selects.date_to = this.date_to;
      // this.updateFromTo(this.selects.year.id);
      if (this.month_id && /^\d+$/.test(this.month_id)) this.selects.month = this.monthsList.find((el) => el.id === +this.month_id) || null;
      await this.loadProjectsList();
      this.disabledProjects = false;

      if (this.project_id && /^\d+$/.test(this.project_id)) {
        this.project = this.projects.find((el) => el.id === +this.project_id) || null;
        if (this.project) {
          await this.loadOrders();
          this.disabledOrders = false;

          if (this.order_id && /^\d+$/.test(this.order_id)) {
            this.order = this.orders.find((el) => el.id === +this.order_id) || null;

            if (this.order) {
              await this.loadMediaPlansAndCommercials();
              this.disabledMediaplans = false;

              if (this.mediaplan_id && /^\d+$/.test(this.mediaplan_id)) {
                this.mediaPlan = this.mediaPlans.find((el) => el.id === +this.mediaplan_id) || null;

                if (this.mediaPlan && this.is_list_view === 'true') {
                  this.isListView = true;
                }
              }
            }
          }
        }
      }
      this.updateQuery();
    },

    updateQuery() {
      setTimeout(() => {
        if (
          this.$route.query.commercial_type_ids != this.selects.commercialTypes.map((c) => c.id).toString() ||
          this.$route.query.agency_ids != this.selects.agencies.map((c) => c.id).toString() ||
          this.$route.query.advertiser_ids != this.selects.advertisers.map((c) => c.id).toString() ||
          this.$route.query.channel_ids != this.selects.channels.map((c) => c.id).toString() ||
          this.$route.query.project_id != this.project?.id ||
          this.$route.query.order_id != this.order?.id ||
          this.$route.query.mediaplan_id != this.mediaPlan?.id ||
          this.$route.query.selected_year != this.selects.year?.id ||
          this.$route.query.date_from != this.selects.date_from ||
          this.$route.query.date_to != this.selects.date_to ||
          this.$route.query.month_id != this.selects.month?.id ||
          this.$route.query.placement_type_id != this.selects.type?.id ||
          this.$route.query.is_list_view != this.isListView.toString() ||
          this.$route.query.project_name != this.selects.project_name
        ) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              commercial_type_ids: this.selects.commercialTypes.map((c) => c.id).toString(),
              agency_ids: this.selects.agencies.map((c) => c.id).toString(),
              advertiser_ids: this.selects.advertisers.map((c) => c.id).toString(),
              channel_ids: this.selects.channels.map((c) => c.id).toString(),
              project_id: this.project?.id,
              order_id: this.order?.id,
              mediaplan_id: this.mediaPlan?.id,
              selected_year: this.selects.year?.id,
              date_from: this.selects.date_from,
              date_to: this.selects.date_to,
              month_id: this.selects.month?.id,
              placement_type_id: this.selects.type?.id,
              is_list_view: this.isListView,
              project_name: this.selects.project_name,
            },
          });
        }
      }, 50);
    },

    // Loading info for project modal
    loadProjectModal(isOpen, modalName, orderCount) {
      this.isOpenModalProject = isOpen;
      this.modalNameOpen = modalName;
      const allChIds = [];
      this.orders?.forEach((o) => {
        const innerArray = o?.order_settings?.map((s) => s.channel_id);
        allChIds.push(...innerArray);
      });
      this.modalProjectBusyChannelIds = allChIds;
      this.modalProjectHasOrders = orderCount > 0 ? true : false;
    },

    // Loading info for order modal
    async loadOrderModal(isOpen, modalName, mediaPlansCount) {
      if (this.project.id !== this.modalEditProjects?.id)
        await this.$store.dispatch('GET_PROJECTS_ID', { id: this.project.id, data: { include: 'channelProjects.channel' } });
      this.modalNameOpen = modalName;
      const listOfChannelsAndTA = [];
      // create list of channel_ids with all TA from MP list e.g. [{channel_id: 1, values: [1,2,3]}]
      this.mediaPlans?.forEach((el) => {
        const ifExist = listOfChannelsAndTA.find((v) => v.channel_id === el.channel_id);
        if (ifExist) ifExist.values.push(el.target_audience_id);
        else listOfChannelsAndTA.push({ channel_id: el.channel_id, values: [el.target_audience_id] });
      });
      this.modalOrderChannelsAndTA = listOfChannelsAndTA;
      this.modalOrderChannelsList = this.modalEditProjects?.channel_projects?.map((e) => ({ id: e.channel.id, name: e.channel.name })) || [];
      this.modalOrderHasMediaPlans = mediaPlansCount > 0 ? true : false;
      this.isOpenModalOrders = isOpen;
    },

    // Loading info for media plan
    loadMediaPlanModal(isOpen, modalName) {
      this.modalNameOpen = modalName;
      this.isOpenModalMediaPlan = isOpen;
    },

    getMonthName(date) {
      if (!this.mediaPlan) return;
      const d = new Date(date);
      return this.monthsList[d.getMonth() + 1]?.name;
    },

    async getData() {
      this.loading = true;
      await Promise.all([
        this.$store.dispatch('GET_AGENCIES', { per_page: 1000 }),
        !(this.placementType && this.placementType.length > 1) ? this.$store.dispatch('GET_PLACEMENT_TYPE', { per_page: 1000 }) : undefined,
        this.$store.dispatch('GET_COMMERCIAL_TYPE'),
        this.year.length < 1 ? this.$store.dispatch('GET_YEAR') : undefined,
        this.$store.dispatch('GET_CHANNELS', { per_page: 1000 }),
      ]);
      this.filterAgenciesList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.agenciesList];
      this.filterYearsList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.year];
      this.filterChannelsList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.channels];
      await this.loadProps();
      this.loading = false;
    },

    async updatePartial(param) {
      this.loading = true;
      this.disabledProjects = true;
      this.disabledOrders = true;
      this.disabledMediaplans = true;
      this.$store.commit('clearMediaPlans');
      this.$store.commit('clearOrders');
      await this.loadProjectsList();
      // param:
      // 'restore'               - restore all P\O\MP selects
      // 'restore-project'       - restore a selected project only (order was deleted)
      // 'restore-project-order' - restore a selected project & order only (media plan was deleted)
      // ''                      - no selects restore (project was deleted)
      if (param) {
        if (this.project) this.project = this.projects.find((el) => el.id === this.project.id) || null;
        await this.loadOrders();

        if (param !== 'restore-project' && this.project) {
          if (this.order) this.order = this.orders.find((el) => el.id === this.order.id) || null;
          await this.loadMediaPlansAndCommercials();
        } else this.order = null;

        if (param !== 'restore-project-order' && this.project && this.order && this.mediaPlan) {
          this.mediaPlan = this.mediaPlans.find((el) => el.id === this.mediaPlan.id) || null;
        } else this.mediaPlan = null;
      } else {
        this.project = null;
      }
      this.disabledProjects = false;
      this.disabledOrders = false;
      this.disabledMediaplans = false;
      this.loading = false;
      this.updateQuery();
    },

    // checks if entity (P\O\MP) crosses selected month range
    isEntityInMonthRange(date_from, date_to, month_start, month_end) {
      const from = new Date(Date.UTC(+date_from.slice(0, 4), date_from.slice(5, 7) - 1, 1)).getTime();
      const to = new Date(Date.UTC(+date_to.slice(0, 4), date_to.slice(5, 7) - 1, 1)).getTime();
      return (from <= month_start && to >= month_start) || (from >= month_start && from <= month_end);
    },

    // Check valid P/O/MP selection after Month filter input
    monthFilterSelected() {
      if (this.selects.year?.id && this.selects.month && this.selects.month.id !== null) {
        if (
          this.order &&
          !this.isEntityInMonthRange(this.order.date_from, this.order.date_to, this.selectedMonthStartEndDays[0], this.selectedMonthStartEndDays[1])
        ) {
          this.mediaPlan = null;
          this.order = null;
        }
        if (
          this.mediaPlan &&
          !this.isEntityInMonthRange(this.mediaPlan.date_from, this.mediaPlan.date_to, this.selectedMonthStartEndDays[0], this.selectedMonthStartEndDays[1])
        ) {
          this.mediaPlan = null;
        }
      }
      this.updateQuery();
    },

    async changeMediaPlanTableListView() {
      this.isListView = !this.isListView;
      this.updateQuery();
    },
  },
};
</script>

<style lang="sass" src="../assets/sass/mediaPlanPlacementTypes.sass"></style>

<style lang="sass">
option.pom-element::before
  display: inline-block
  width: 20px
  content: ''
option.pom-element.pom-element-is-closed::before
  content: '🔒'
</style>
